<template>
  <div>
    <el-main>
      <head-layout
        :head-btn-options="headBtnOptions"
        head-title="境内项目人员信息编辑"
        @head-cancel="headCancel"
        @head-save="headSave"
        @head-saveBack="headSave(true)"
        @head-submit="headSubmit"
      ></head-layout>
      <el-form :model="dataForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm"
               style="margin-top: 20px;">
        <el-row :gutter="24">
<!--          <el-col :span="8">-->
<!--            <el-form-item label="项目编码" prop="projectCode">-->
<!--              <template slot="label">-->
<!--                <span>项目编码:</span>-->
<!--              </template>-->
<!--              <el-input-->
<!--                readonly="true"-->
<!--                v-model="dataForm.projectCode"-->
<!--                controls-position="right"-->
<!--                placeholder="请输入项目编码"-->
<!--              ></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :span="8">
            <el-form-item label="统计日期" prop="statDate">
              <template slot="label">
                <span style="color:red">* </span>
                <span>统计日期:</span>
              </template>
              <el-date-picker
                disabled="true"
                v-model="dataForm.statDate"
                type="date"
                placeholder="请选择统计日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="填报日期" prop="updateTime">
              <template slot="label">
                <span>填报日期:</span>
              </template>
              <el-date-picker
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="dataForm.updateTime"
                type="date"
                disabled="true"
                placeholder="保存后自动生成"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="form-divider"></div>
      </el-form>
      <el-form :model="dataForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="项目名称" prop="projectName">
              <template slot="label">
                <span>项目名称:</span>
              </template>
              <el-input
                readonly="true"
                v-model="dataForm.projectName"
                controls-position="right"
                placeholder="项目名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="业务类型" prop="businessTypeName">
              <template slot="label">
                <span>业务类型:</span>
              </template>
              <el-input
                readonly="true"
                v-model="dataForm.businessTypeName"
                controls-position="right"
                placeholder="业务类型"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目状态" prop="projectStatusName">
              <template slot="label">
                <span>项目状态:</span>
              </template>
              <el-input
                readonly="true"
                v-model="dataForm.projectStatusName"
                controls-position="right"
                placeholder="项目状态"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="州/省" prop="provinceName">
              <template slot="label">
                <span>州/省:</span>
              </template>
              <el-input
                readonly="true"
                v-model="dataForm.provinceName"
                controls-position="right"
                placeholder="州/省"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目经理" prop="pmCame">
              <template slot="label">
                <span>项目经理:</span>
              </template>
              <el-input
                disabled="true"
                v-model="dataForm.pmCame"
                controls-position="right"
                placeholder="由项目数据带出"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="phoneNumber">
              <template slot="label">
                <span>联系电话:</span>
              </template>
              <el-input
                disabled="true"
                v-model="dataForm.phoneNumber"
                controls-position="right"
                placeholder="由项目数据带出"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="总人数" prop="totalCount">
              <template slot="label">
                <span>总人数:</span>
              </template>
              <el-input
                readonly="true"
                v-model="totalChineseCnt"
                controls-position="right"
                placeholder="总人数"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <head-layout
        head-title="项目人员信息"
      ></head-layout>
      <form-layout
        ref="formLayout"
        :column="form1Column"
        :dataForm.sync="dataForm"
      ></form-layout>
      <head-layout
        head-title="项目现场信息"
      ></head-layout>
      <form-layout
        ref="formLayout"
        :column="form2Column"
        :dataForm.sync="dataForm"
      ></form-layout>
    </el-main>
  </div>
</template>
<script>

import GridLayout from "@/views/components/layout/grid-layout.vue";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import {saveDomestic, getDomesticDetail} from "@/api/foreignReport/domesticReport";
import {mapGetters} from "vuex";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {processList as getProcessList} from "@/api/plugin/workflow/process";


export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  mixins: [exForm, draft],
  data() {
    return {
      processTemplateKey: "reportDomestic",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      type: '',
      ruleForm: {},
      rules: {
        name: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
          {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
      },
      dataForm: {},
      orgCode: "",
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headBtnOptions() {
      let buttonBtn = [];
      buttonBtn.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
        });
      return buttonBtn;
    },
    form1Column() {
      return [
        {
          label: "公司在册职工",
          prop: "regeditedChineseCnt",
          labelWidth: 300,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          readonly: true,
          rules: [{
            required: true,
            message: "请输入公司在册职工数",
            trigger: "blur"
          }, {
            pattern: /^[0-9]*[1-9][0-9]*$/,
            message: '请输入正整数',
            trigger: "blur"
          }]
        },
        {
          label: "分包商（含劳务）人数",
          prop: "subcontractorChineseCnt",
          labelWidth: 300,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          readonly: true,
          rules: [{
            required: true,
            message: "请输入分包商（含劳务）人数",
            trigger: "blur"
          }, {
            pattern: /^[0-9]*[1-9][0-9]*$/,
            message: '请输入正整数',
            trigger: "blur"
          }]
        },
      ]
    },
    form2Column() {
      return [
        {
          label: "项目进展情况",
          prop: "projectProgress",
          labelWidth: 120,
          type: 'textarea',
          maxlength: 500,
          span: 24,
          showWordLimit: true
        },
      ]
    },
    totalChineseCnt() {
      if(Number(this.dataForm.regeditedChineseCnt) + Number(this.dataForm.subcontractorChineseCnt) == 0){
        return "";
      }else {
        return (Number(this.dataForm.regeditedChineseCnt) + Number(this.dataForm.subcontractorChineseCnt))
      }
    },
  },
  methods: {
    //提交
    headSubmit() {
      if (this.dataForm.id) {
        if (this.dataForm.statDate) {
          this.$refs.formLayout.$refs.form.validate(valid => {
            if (valid) {
              this.$confirm("确定将提交审核?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
              })
                .then(() => {
                  this.$loading();
                  let dataForm = this.$refs.formLayout.dataForm
                  this.rpTaskAttributeSubmit(dataForm);
                })
                .then(() => {
                  this.$router.$avueRouter.closeTag();
                  this.$router.back();
                  this.$loading().close();
                });
              this.$refs.formLayout.$refs.form.allDisabled = false
            }
          })
        } else {
          this.$message.warning("统计日期为空");
        }
      } else {
        this.$message.warning("请先保存数据");
      }
    },
    rpTaskAttributeSubmit(data) {
      this.dataForm.totalCount = this.totalChineseCnt
      if (!this.dataForm.id) {
        this.dataForm.createUserName = this.userInfo.real_name
      }
      this.dataForm.updateUserName = this.userInfo.real_name
      this.dataForm.projectArea = 1
      this.dataForm.orgCode = this.orgCode
      this.formProcess.id = data.id;
      this.formProcess.paperName = this.dataForm.paperName;
      this.handleStartProcess5(true,true).then((res) => {
        data.processInsId = res.data.data;
        data.dataState = 2; // 设置状态为新增生成 审核中
        saveDomestic(data).then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        })
      });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    },
    headSave(clo = false) {
      this.dataForm.totalCount = this.totalChineseCnt
      if (this.dataForm.statDate) {
        if (!this.dataForm.id) {
          this.dataForm.createUserName = this.userInfo.real_name
        }
        this.dataForm.updateUserName = this.userInfo.real_name
        this.dataForm.projectArea = 1
        this.dataForm.orgCode = this.orgCode
        saveDomestic(this.dataForm).then((res) => {
          if (res.data.code === 200) {
            this.dataForm = res.data.data;
            this.$message.success("保存成功");
          }
        }).then(() => {
          if (clo) {
            this.$router.$avueRouter.closeTag();
            this.$router.back();
          }
        })
      } else {
        this.$message.warning("统计日期为空");
      }
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  },
  mounted() {
    this.dataForm = this.$route.query.data;
    this.orgCode = this.$route.query.orgCode
    let id = this.$route.query.id
    this.$refs.formLayout.$refs.form.allDisabled = true
    if (id && id != -1) {
      getDomesticDetail(id).then((res) => {
        if (res.data.code === 200) {
          this.dataForm = res.data.data
        }
      })
    } else {
      this.dataForm.id = null
    }
  },
}
</script>

<style>
.form-divider {
  display: block;
  height: 1px;
  margin: 16px 0;
  background-color: rgba(0, 0, 0, 0.12);
  border: none;
  overflow: hidden;
}

.form-divider::before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}


</style>
